body {
    background: #f0f8ff;
  }
  
  .post-wrap {
    max-width: 1924x;
    padding-top: 20px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .post-item {
    padding: 10px;
    cursor: pointer;
  }
  
  .post-item * {
    transition: 0.3s linear;
  }
  
  .item-content {
    border-radius: 20px;
    background: linear-gradient(90deg, #9cc4ab,#70bda0,#21b59c);
    padding: 20px;
    text-align: center;
  }
  
  .item-icon {
    margin-bottom: 20px;
  }
  
  .item-icon:before {
    content: "";
    font-family: FontAwesome;
    color: #F09EA3;
    font-size: 50px;
    line-height: 1;
  }
  
  .item-icon.photo:before {
    content: "\f030";
  }
  
  .item-icon.video:before {
    content: "\f03d";
  }
  
  .item-icon.gift:before {
    content: "\f06b";
  }
  
  .item-icon.group:before {
    content: "\f0c0";
  }
  
  .item-icon.tree:before {
    content: "\f00c";
  }
  
  .item-icon.anchor:before {
    content: "\f13d";
  }
  
  .post-item:hover .item-icon, .post-item:hover .item-body h3, .post-item:hover .item-body p {
    transform: translateY(-8px);
  }
  
  .item-body {
    color: linear-gradient(90deg, #010114,#171360,#010114);
    font-size: 14px;
  }
  
  .item-body h3 {
    font-weight: 500;
    color: linear-gradient(171deg, #121212,#181818);
    margin-bottom: 15px;
    transition-delay: 0.05s;
  }
  
  .item-body p {
    transition-delay: 0.1s;
  }
  
  @media (min-width: 768px) {
    .post-item {
      flex-basis: 50%;
      flex-shrink: 0;
    }
  }
  @media (min-width: 960px) {
    .post-item {
      flex-basis: 33.333333333%;
    }
  }/*# sourceMappingURL=about.css.map */