

.home {
  height: 100vh;
  background: url("../../Assets/mainbackground.jpeg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  position: relative;
}

.secContainer .homeText {
  text-align: center;
}
.secContainer .homeText .title {
  font-size: 3rem;
  font-weight: 600;
  line-height: 2.5rem;
}
.secContainer .homeText .subTitle {
  opacity: 0.7;
  font-size: 24px;
  font-weight: 300;
  padding: 1rem 0;
  max-width: 100%;
  text-align: center;
  margin: auto;
  font-weight: bold;
}
.secContainer .homeText .btn {
  text-decoration: none;
  display: inline-block;
  padding: 12px 40px;
  margin: 10px 20px;
  border-radius: 30px;
  background-image: linear-gradient(90deg, #f0f3f4,#9ef5f5,#4df5f5,#01bcbc,#289c9c);
  background-position: 100% 0;
  background-size: 200% 200%;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  font-weight: 300;
  color: white;
  box-shadow: 0 16px 32px 0 rgba(0, 40, 120, .35);
  transition: 1.6s;
}

.secContainer .homeText .btn:hover {
  box-shadow: 0 0 0 0 rgba(0, 40, 120, 0);
  background-position: 0 0;
}

@media screen and (max-width: 769px) {
  .secContainer .homeText .title {
    font-size: 2.5rem;
    margin: auto;
  }
  .secContainer .homeText .subTitle {
    font-size: 18px;
  }
  .secContainer .homeText .btn {
    font-size: 13px;
  }
}/*# sourceMappingURL=home.css.map */